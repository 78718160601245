import KDataTable from '@components/k-data-table';
import KHeading from '@components/k-heading';
import { AddUser } from 'react-iconly';
import { SadaEmployees } from './styles';
import config from './config';
import useModal from '@hooks/modal.hook';
import useList from '@hooks/list.hook';
import { type IEmployee } from '@types/employee.type';
import { navigate } from 'hookrouter';

const Employees = () => {
  const { open } = useModal('invite');
  const [dataSource, loading]: [[IEmployee]] = useList('employees');
  return (
    <SadaEmployees>
      <KHeading title="Employees" icon={<AddUser />} xl onAction={open} />
      <KDataTable
        config={{
          ...config,
          loading,
          dataSource,
          onRow: ({ email }) => ({
            onClick: () => {
              navigate(`/employees/${email}`);
            },
          }),
        }}
        filters
      />
    </SadaEmployees>
  );
};

export default Employees;
